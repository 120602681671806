.App {
  text-align: center;
}
.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*height: 100vh; /* Set the wrapper's height to match the viewport */
}
.center-page {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 725px;
  height: 600px;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textarea{
  width: 725px;
  height: 225px;
  padding: 0px;
  border-width: 1px;
  text-align: left;
}
.label {
  margin-top: 5px;
  align-self: flex-start;
  font-size: 14px;
  font-family: poppins-semibold,poppins,sans-serif;
}
/* .div-btn{
  margin-top:20px ;
} */
.button {
  margin-top:30px;
  margin-bottom: 30px;
  height: 34px;
  width: 280px;
  background-color: #0984e3;
  color: #ffffff;
  border-width: 0px;
  border-radius: 20px;
  padding: 0px;
  font-weight: bold;
  font-size: 14px;
  font-family: poppins-semibold,poppins,sans-serif;
  border:0px;
}
.horizontal-line {
  width: 725px;
  border: none;
  border-bottom: 1px solid #000000;
}
.pdf-down {
  /* align-self: flex-end; */
  /* justify-content: flex-end; */
  font-size: 14px;
  color: #0984e3;
  font-family: poppins-semibold,poppins,sans-serif;
  /* margin-bottom: 10px; */

}
.img-cen {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 725px;
  height: 600px;
}
.btn-pdf {
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  flex-direction: row;
  width: 725px;
}
/* .btn-align { */
   /* justify-content: flex-start; */
   /* align-items: flex-start; */
   /* align-self: flex-start; */
  
/* } */
.scrollable-container {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 600px;
  width: 725px; 
  background-color: #fff;
  padding: 0 5px 0 0;
  margin: 0 0px 10px 0;
}
.scrollable-container::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #ffffff;
  border: 1px solid #aaa7a7;
  -webkit-box-shadow: inset 0 0 6px white;
  border-radius: 5px; 
}

.scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 6px #aaa7a7;
}
.scrollable-container:hover {
  overflow-y: auto;
  padding-right: 1px;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items:center;
  gap: 10px; 

}
.img-grid img {
  width: 0px;
  height: auto;
  
}
.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* margin: 0px;
  padding: 0px; */
}

.serial-number {
  margin-bottom: 50px;
}

.image-wrapper img {
  width: 50%;
  height: auto;  
}