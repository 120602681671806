.header {
    margin-bottom:30px;
}
.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*height: 100vh; /* Set the wrapper's height to match the viewport */
  }
  .center-page {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 725px;
    height: 600px;
  }
  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .label {
    margin-top: 5px;
    margin-bottom: 10px;
    align-self: flex-start;
    font-size: 14px;
    font-family: poppins-semibold,poppins,sans-serif;
  }
  .inputbtn{
    height: 34px;
    width: 340px;
    padding-right: 0px;
    border-radius: 20px;
     border: 1px solid #ced4da;
    border-width:1px;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
    padding-left: 20px;
    font-weight: bold;
    outline: none;
  }
  
  .submitbtn {
    margin-top:10px;
    margin-bottom: 30px;
    height: 38px;
    width: 180px;
    background-color: #087f5b;
    color: #ffffff;
    border-width: 0px;
    border-radius: 20px;
    padding: 0px;
    font-weight: bold;
    font-size: 16px;
    font-family: poppins-semibold,poppins,sans-serif;
    border:0px;
  }

  .horizontal-line {
    width: 362px;
    border: none;
    border-bottom: 1px solid #000000;
  }
  .label-res {
    margin-top:30px;
    font-size: 24px;
    font-weight: bold;
  }
  .span{
    margin-top: 40px;
    font-size: 28px;
    font-weight: bold;
  }

  .validate-txt {
   font-size: 16px;
   font-family: poppins-semibold,poppins,sans-serif;
   color: #e03131;
  }